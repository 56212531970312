:root {
    --website-color-red: #eb5757;
    --website-color-blue: #7886ff;
    --website-color-gray-1: #e0e0e0;
    --website-color-gray-2: #313131;
    --website-color-gray-3: #a3a3a3;
    --website-color-orange-1: #f2c94c;
}

a {
    text-decoration: none;
}

@keyframes showItem {
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
}
