img,
p,
body {
    font-family: PingFang HK, Arial, Helvetica, sans-serif;
    margin: 0;
}

a {
    color: black;
    text-decoration: none;
}

a:link {
    text-decoration: none;
}
a:visited {
    text-decoration: none;
}
